import "./brand.css";
import "./App.css";
import Contact from "./Pages/Contact/Contact";
import Hero from "./Pages/Hero/Hero";
import WhoWeAre from "./Pages/WhoWeAre/WhoWeAre";
import RecentProjects from "./Pages/RecentProjects/RecentProjects";
import WhatWeOffer from "./Pages/WhatWeOffer/WhatWeOffer";
import Fixed from "./FixedComponents/Fixed";
import OurTeam from "./Pages/OurTeam/OurTeam";
// import Footer from "./Pages/Footer/Footer";
import Toast from "./Partials/Toast";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Project from "./Pages/Project/Project";
import Menu from "./FixedComponents/Menu/Menu";
import ScrollToTop from "./Partials/ScrollToTop";
import Wrapper from "./Partials/Warpper";
import LandingPage from "./LandingPages/Landing";
import BackButton from "./Pages/Project/BackButton";
import React from "react";
import OurClients from "./Pages/OurClient/OurClients";

function App() {
  //for en-he button later on...
  function setLanguage(language) {
    if (localStorage.getItem("language")) {
      return;
    }
    const chosenLanguage = language || "en";
    localStorage.setItem("language", chosenLanguage);
  }

  setLanguage();
  const menuColor = getComputedStyle(document.documentElement).getPropertyValue(
    "--colorMain"
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/projects">
          <ScrollToTop />
          <Fixed />
          <RecentProjects />
          <Contact />
        </Route>
        <Route path="/tech/:tech_name">
          <Fixed />
          <LandingPage />
        </Route>
        <Route path="/project/:project_name">
          <Menu color={menuColor} />
          <ScrollToTop />
          <Wrapper>
            <Fixed />
            <BackButton />
            <Project />
          </Wrapper>
        </Route>
        <Route path="/">
          <Fixed />
          <Hero />
          <WhoWeAre />
          <OurTeam />
          <WhatWeOffer />
          <OurClients />
          <RecentProjects projectsAmount={6} />
          <Contact />
        </Route>
      </Switch>

      <Toast />
    </BrowserRouter>
  );
}

export default App;
