import alvaLinks from "../Images/logos/alvalinks.svg";
import davar from "../Images/logos/davar.svg";

import tlvUni from "../Images/logos/tlv-uni.svg";
import haifaUni from "../Images/logos/haifaUni.svg";
import lezel from "../Images/logos/lezel.svg";
import tulu from "../Images/logos/tulu.svg";
import keshet from "../Images/logos/keshet.svg";
import technion from "../Images/logos/technion.svg";

export const CLIENTS_LOGOS = [
  alvaLinks,
  davar,
  tlvUni,
  lezel,
  tulu,
  haifaUni,
  keshet,
  technion,
];

export const RECOMMENDATIONS = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor ",
  "Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
  "fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in ",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor Nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ",
];
