// import "swiper/swiper-bundle.min.css";
import "./Recommendations.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

export default function Recommendations({ recommendations }) {
  return (
    <div className="recommendations-container">
      <Swiper
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 5000, // Delay between slides in ms
          disableOnInteraction: false, // Keep autoplay running after user interaction
        }}
        pagination={{ clickable: true }}
        slidesPerView={1}
        spaceBetween={20}
        loop={true}
        className="recommendations-swiper">
        {recommendations.map((recommend, index) => (
          <SwiperSlide key={index}>
            <div className="recommend-text">{recommend}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
