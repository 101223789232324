import React from "react";
import "./OurClients.css";
import { ReactComponent as MobileGit } from "../../Images/mobile-git.svg";
import git from "../../Images/white-git.svg";
import { ReactComponent as Quote } from "../../Images/quote.svg";
import Recommendations from "./Recommendations";
import { CLIENTS_LOGOS, RECOMMENDATIONS } from "../../Content/clients";

export default function OurClients() {
  return (
    <div className="container" id="OurClients">
      <div className="headline">
        <MobileGit className="mobileGit" />
        <img src={git} className="git" alt="git" />
        <h1>OUR CLIENTS</h1>
        <div className="git"></div>
      </div>
      <div className="logos">
        {CLIENTS_LOGOS.map((logo) => {
          return <img src={logo} alt="logoes" key={logo} />;
        })}
      </div>
      <div className="recommendations-headline">
        <Quote />
        <Recommendations recommendations={RECOMMENDATIONS} />
      </div>
    </div>
  );
}
